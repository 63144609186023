<template>
  <div>
    <div>
      <div class="project-search">
        <div class="flex search-text align-center">
          <div>
            <span>教职工号：</span>
            <input v-model="pageInfo.code" placeholder="教职工号" class="searchInput" />
          </div>
          <div class="marginLeft10">
            <span>姓名：</span>
            <input v-model="pageInfo.name" placeholder="教师姓名" class="searchInput" />
          </div>
          <button class="searchBtn" @click="search">搜索</button>
          <button class="resetBtn" @click="reset(null)">重置</button>
        </div>
      </div>
      <div class="project-table" style="margin-top: 20px">
        <div class="flex align-center justify-between paddingBTM20">
          <div class="fontSize20gay">教师列表</div>
          <div class="flex">
            <div>
              <el-upload class="upload-demo" :action="(projectName?'/'+projectName:'')+'/teacher/import?Authorization=Bearer '+token" list-type="picture" accept=".xls,.xlsx" :show-file-list="false" :on-progress="beforxfg" :on-success="fileVb">
                <button class="exportBtn">导入教师</button>
              </el-upload>
            </div>
            <button class="exportBtn marginLR10" @click="showDown('teacher')">下载模板</button>
            <div><button class="addBtn" @click="showEdit('')"> + 新增</button></div>
          </div>
        </div>
        <el-table :data="tableData" border row-key="id"
                  :header-cell-style="{'text-align':'center','color':'#999999','padding':'16px 0','background-color':'#EBEBEB'}"
                  :cell-style="{'text-align':'center'}">
          <el-table-column prop="code" label="教职工号" />
          <el-table-column prop="name" label="姓名" />
          <el-table-column prop="department" label="单位" />
          <el-table-column prop="username" label="用户名" />
          <el-table-column prop="phone" label="手机" />
          <el-table-column prop="email" label="邮箱" />
          <el-table-column label="数据来源">
            <template #default="scope">
              {{scope.row.from==1?'吉利相伴':'系统创建'}}
            </template>
          </el-table-column>
          <el-table-column prop="time" label="创建时间" />
          <el-table-column label="账号状态">
            <template #default="{ row }">
              <el-tag v-if="accountStatus(row.disableEndDate)" type="success">正常</el-tag>
              <el-tag v-else type="warning">受限</el-tag>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="150">
            <template #default="scope"><!-- scope.$index 表示 序列号 0、1、2 -->
              <el-button link type="primary" style="color: #3769B9" @click="showEdit(scope.row)">编辑</el-button>
<!--              <el-button link type="primary" style="color: #3769B9" @click="showRenew(scope.row.id)">删除</el-button>-->
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="密码" width="150">
            <template #default="scope"><!-- scope.$index 表示 序列号 0、1、2 -->
              <el-button link type="primary" style="color: #3769B9" @click="resetPassword(scope.row.id)">重置</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="flex justify-center paddingTD20">
          <!--   分页     -->
          <el-pagination
              :currentPage="pageInfo.page"
              :page-size="pageInfo.limit"
              :page-sizes="[10, 20, 50, 100]"
              :small="true"
              :disabled="false"
              :background="true"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageInfo.total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <!-- 操作窗口   -->
    <editDiv v-model="dialogFormVisible" :Row="Info" @closeDiv="closeDiv" @getList="getList" ref="editDiv"></editDiv>
  </div>
</template>

<script>
import editDiv from "@/view/user/teacherEdit.vue"
import {getTeacherPage,downFile,resetTeacherPassword} from "@/api/api";
import {projectName} from "@/utils/config"
import {resetStudentPassword} from "../../api/api";
import {accountStatus} from "@/utils/common";
export default {
  name: "studentList",
  components:{
    editDiv,
  },
  data(){
    return{
      token:localStorage.getItem('token'),
      tableData:[],
      dataInfo:[],
      projectName:projectName,
      Info:null,
      dialogFormVisible:false,
      pageInfo:{
        limit:10,
        name:'',
        code:'',
        page:1,
        total:0
      },
      exportIds:''
    }
  },
  mounted() {
    this.getList();
  },
  methods:{
    accountStatus,
    reset(){
      this.pageInfo.name='';
      this.pageInfo.code='';
    },
    search(){
      this.pageInfo.limit=10;
      this.pageInfo.page=1;
      this.getList();
    },
    getList(){
      getTeacherPage(this.pageInfo).then(res=>{
        this.pageInfo.total=parseInt(res.data.total);
        this.tableData=res.data.records;
      })
    },
    showEdit(row){
      this.dialogFormVisible=true;
      this.Info=row;
      let data='';
      if(row) {
        data = JSON.stringify(this.Info);
      }
      this.$refs.editDiv.getInfo(data);//调用子组件方法
    },
    showRenew(id){
      this.dialogFormVisible1=true;
      this.$refs.renewDiv.getInfo(id);//调用子组件方法
    },
    resetPassword(id){
      this.$root.ElMessageBox.confirm(
          '你确定要重置该用户的密码吗?',
          '提示',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
            draggable: true,
          }
      ).then(() => {
        resetTeacherPassword(id).then(res=>{
          if(res.success){
            this.$root.ElMessage({type:'success',message:res.message});
          }else{
            this.$root.ElMessage.error(res.message);
          }
        })
      })
    },
    closeDiv(){
      this.dialogFormVisible=false;
    },
    handleSizeChange(e){//修改每页显示条数
      this.pageInfo.limit=e;
      this.pageInfo.page=1;
      this.getList();
    },
    handleCurrentChange(e){
      this.pageInfo.page=e;
      this.getList();
    },
    showDown(name){
      downFile(name);
    },
    beforxfg(){
      this.loading = this.$root.loadingOpen();
    },
    fileVb(res){
      if(res.success){
        this.$root.ElMessage({type:'success',message:res.message});
        this.getList();
      }else{
        this.$root.ElMessage.error(res.message);
      }
      this.loading.close();
    }
  }
}
</script>

<style lang="scss" scoped>
.data-title{font-size: 15px;color: #000000}
.data-content{
  font-size: 13px;display: flex;justify-content: space-between;margin-top: 15px;
}
</style>